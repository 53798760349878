<template>
  <div :class="_classes">
    <pop-over
      :title="$t('views.confirm.removeItem.title')"
      :displayedMessage="$t('views.confirm.removeItem.message')"
      :canInput="false"
      @confirm="$emit('removeItem')"
    >
      <sd-button :disabled="record.linkedToShipment"
      class="btn-icon" type="default" shape="circle"
      :tooltip="record.linkedToShipment ? $t('actions.productIsAlreadyLinkedToAShipment') : $t('actions.remove')">
        <sd-feather-icons type="x-circle" size="16" />
      </sd-button>
    </pop-over>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import PopOver from '@/components/modals/PopOver.vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'RemoveItemButton',
  emits: ['removeItem'],
  components: {PopOver},
  props: {
    _classes: VueTypes.string.def(''),
    record: VueTypes.object.def({})
  }
});
</script>
