<template>
  <table-style-wrapper>
    <table-wrapper class="table-responsive">
      <a-table
        size="small"
        :pagination="false"
        :columns="columns"
        :data-source="$_.filter(data, (row) => row._destroy === 0)"
        :rowKey="(row) => $_.findIndex(data, row)"
      >
        <template #name="{record}">
          <select-field
            v-if="!record.name"
            class="editable-cell editable-table-cell hide-input-label"
            size="small"
            :options="availableOptions"
            :fieldKey="[parentKey, $_.findIndex(data, record), 'name']"
            :rules="[required]"
            :field-value="data[$_.findIndex(data, record)].name"
            @update:fieldValue="handleAdd($_.findIndex(data, record), $event)"
          />
          <template v-else>
            {{ parentKey === 'vat' ? $t(`models.shipmentQuote.attributes.${record.name}`) : record.name }}
          </template>
        </template>
        <template #totalValue="{record}">
          {{$_.round(record.quantity * record[$_.snakeCase('valuePerItem')], 2)}}
        </template>
        <template #valuePerItem="{record}">
          {{$_.round(record[$_.snakeCase('valuePerItem')], 2)}}
        </template>
        <template #value="{record}">
          <number-field
            class="editable-cell editable-table-cell hide-input-label"
            size="small"
            :min="0"
            :fieldKey="[parentKey, $_.findIndex(data, record), 'value']"
            :rules="[requiredNumber]"
            v-model:field-value="data[$_.findIndex(data, record)].value"
          />
        </template>
        <template #percentage="{record}">
          <number-field
            class="editable-cell editable-table-cell hide-input-label"
            size="small"
            :min="0"
            :max="100"
            :percentage="true"
            :fieldKey="[parentKey, $_.findIndex(data, record), 'percentage']"
            v-model:field-value="data[$_.findIndex(data, record)]['percentage']"
          />
        </template>
        <template #appliedOn="{record}">
          <a-radio-group
            class="applied-on-radio"
            v-model:value="data[$_.findIndex(data, record)]['ci']"
          >
            <a-radio :value="true">
              {{$t('models.shipmentQuote.attributes.ci')}}
            </a-radio>
            <a-radio :value="false">
              {{$t('models.shipmentQuote.attributes.cif')}}
            </a-radio>
          </a-radio-group>
        </template>
        <template #note="{record}">
          <text-field
            class="editable-table-cell hide-input-label"
            size="small"
            :fieldKey="[parentKey, $_.findIndex(data, record), 'note']"
            v-model:field-value="data[$_.findIndex(data, record)].note"
          />
        </template>

        <template #actions="{record}">
          <remove-item-button
            v-if="canRemove(record)"
            :record="record"
            _classes="single-table-action"
            @removeItem="handleRemove(record)"
          />
        </template>

        <template #footer>
          <a-row :gutter="[4,0]">
            <a-col v-if="!templateMode" :xs="6" class="total-text">Total</a-col>
            <a-col v-if="!templateMode" class="total-value">{{totalSum}}</a-col>
            <a-col class="add-items-dropdown" v-if="!$_.isEmpty(availableOptions)">
              <a class="btn-icon add-item-btn mt-10" @click="addItem({})" >
                <a-tooltip placement="right" :title="$t('actions.add')">
                  <sdFeatherIcons type="plus-circle" size="16" />
                </a-tooltip>
              </a>
            </a-col>
          </a-row>
        </template>
      </a-table>
    </table-wrapper>
  </table-style-wrapper>
</template>

<script>
import {
  computed, defineComponent, ref, toRefs, watch
} from 'vue';
import VueTypes from 'vue-types';
import TextField from '@/components/shared/fields/Text';
import NumberField from '@/components/shared/fields/Number';
import SelectField from '@/components/shared/fields/Select';
import {requiredNumber, required} from '@/helpers/validationRules';
import RemoveItemButton from '@/components/registration/RemoveItemButton';
import {TableStyleWrapper} from '@/components/tables/style.js';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import _ from 'lodash';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'ShipmentQuoteShipmentDetails',
  emits: ['updateSubForm'],
  props: {
    parentKey: VueTypes.string.required,
    categoryId: VueTypes.string.required,
    title: VueTypes.string.required,
    items: VueTypes.array,
    unlinkedItems: VueTypes.array,
    destroyAll: VueTypes.bool.def(false),
    template: VueTypes.bool.def(false)
  },
  components: {
    TextField, NumberField, SelectField, RemoveItemButton,
    TableWrapper, TableStyleWrapper
  },
  setup(props, context) {
    const {t} = useI18n();
    const columns = computed(() => {
      const allCols = [{
        title: props.title,
        slots: {customRender: 'name'},
        dataIndex: 'name',
        width: '25%'
      },
      {
        title: t('models.shipmentQuote.attributes.totalValue'),
        dataIndex: 'totalValue',
        slots: {customRender: 'totalValue'},
        hidden: props.parentKey !== 'duties'
      },
      {
        title: t('models.shipmentQuote.attributes.quantity'),
        dataIndex: 'quantity',
        hidden: props.parentKey !== 'duties'
      },
      {
        title: t('models.shipmentQuote.attributes.valuePerItem'),
        dataIndex: _.snakeCase('valuePerItem'),
        slots: {customRender: 'valuePerItem'},
        hidden: props.parentKey !== 'duties'
      },
      {
        title: t('models.shipmentQuote.attributes.value'),
        dataIndex: 'value',
        slots: {customRender: 'value'},
        hidden: props.template
      },
      {
        title: t('models.shipmentQuote.attributes.percentage'),
        dataIndex: 'percentage',
        slots: {customRender: 'percentage'},
        hidden: (props.parentKey !== 'vat' && props.parentKey !== 'duties') || props.template
      },
      {
        title: t('models.shipmentQuote.attributes.appliedOn'),
        dataIndex: 'ci',
        slots: {customRender: 'appliedOn'},
        hidden: props.parentKey !== 'duties' || props.template
      },
      {
        title: t('models.shipmentQuote.attributes.note'),
        dataIndex: 'note',
        slots: {customRender: 'note'},
        hidden: props.template
      },
      {
        slots: {customRender: 'actions'}
      }];
      return _.filter(allCols, (col) => !col.hidden);
    });

    const data = ref([]);
    const deletedItemsIds = ref([]);
    const {unlinkedItems} = toRefs(props);
    const templateMode = ref(props.template);
    const availableOptions = computed(() => {
      unlinkedItems.value; deletedItemsIds.value;

      const options = [];
      _.forEach(deletedItemsIds.value, (id) => {
        const item = _.find(props.items, {id});
        options.push({label: item.name, value: item.name});
      });

      _.forEach(unlinkedItems.value, (unlinkedItem) => {
        if (_.find(data.value, (item) => item.name === unlinkedItem.name)) return;
        options.push({label: unlinkedItem.name, value: unlinkedItem.name});
      });
      return options;
    });

    const hasPercentage = computed(() => props.parentKey === 'duties' || props.parentKey === 'vat');
    const addItem = (item) => {
      data.value.push({
        [_.snakeCase('quoteCategoryId')]: props.categoryId,
        id: item.id,
        name: item.name,
        note: item.note || '',
        value: item.value || 0,
        percentage: hasPercentage.value ? item.percentage || 0 : undefined,
        ci: props.parentKey === 'duties' ? item.ci || false : undefined,
        _destroy: 0,
        quantity: item.quantity,
        [_.snakeCase('valuePerItem')]: item[_.snakeCase('valuePerItem')]
      });
    };

    watch(() => props.items, (categoryItems) => {
      data.value = [];
      deletedItemsIds.value = [];
      if (_.isEmpty(categoryItems)) {
        addItem({});
      } else {
        _.forEach(categoryItems, (item) => {
          addItem(item);
        });
      }
    }, {deep: true, immediate: true});


    const canRemove = (record) => {
      return props.parentKey !== 'vat' && props.parentKey !== 'duties' &&
        !(props.parentKey === 'service' && isEorOrIOR(record.name));
    };

    const isEorOrIOR = (name) => {
      return _.includes(_.lowerCase(name), 'eor') || _.includes(_.lowerCase(name), 'ior');
    };

    const handleRemove = (record) => {
      const index = _.findIndex(data.value, record);
      if (index < 0) return;

      if (record.id) {
        deletedItemsIds.value.push(record.id);
        data.value[index]._destroy = 1;
      } else {
        data.value.splice(index, 1);
      }
    };

    const handleAdd = (index, name) => {
      const existingIndex = _.findIndex(data.value, {name});
      data.value[index].name = name;
      if (existingIndex > -1) {
        data.value[index]._destroy = 0;
        data.value[index].id = data.value[existingIndex].id;
        data.value[index].value = 0;
        data.value[index].note = '';

        _.pull(deletedItemsIds.value, data.value[existingIndex].id);
        data.value.splice(existingIndex, 1);
      }
    };

    const totalSum = computed(() => {
      return _.round(_.sumBy(_.filter(data.value, (item) => item._destroy !== 1), 'value'), 2);
    });

    watch(data, (formData) => {
      context.emit('updateSubForm', formData);
    }, {immediate: true, deep: true});

    watch(() => props.destroyAll, (value) => {
      _.forEach(data.value, (item) => item._destroy = value ? 1 : 0);
    });

    return {
      columns,
      data,
      requiredNumber,
      totalSum,
      handleRemove,
      handleAdd,
      deletedItemsIds,
      availableOptions,
      addItem,
      required,
      canRemove,
      templateMode
    };
  }
});
</script>

<style lang="scss" scoped>
.editable-table-cell.hide-input-label {
  padding-top: 10px;
}

.ant-table-footer .total-text,
.ant-table-footer .total-value {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.ant-table-footer .total-value {
  padding-left: 12px !important;
}

.ant-table-footer .add-items-dropdown {
  width: 50px !important;
  padding-left: 20px !important;
}

.btn-icon.add-item-btn i{
  vertical-align: middle
}
</style>
