import Styled from 'vue3-styled-components';

const QuoteFormWrapper = Styled.div`
  .remove-item-btn button{
    vertical-align: middle !important;
    height: 100% !important;

    i svg{
      width: 22px;
      height: 22px;
    }
  }

  .ant-radio-group.ant-radio-group-outline.ant-radio-group-default.applied-on-radio {
    padding-bottom: 20px;
    span {
      display: inline-block !important;
    }
  }
`;

export {
  QuoteFormWrapper
};
