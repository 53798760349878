<template>
  <a-popover v-model:visible="visible" :trigger="action" :placement="placement">
    <template v-slot:content>
      <div class="ant-popover-inner-content">
        <div class="ant-popover-message pr-20">
          <div role="img" class="anticon">
            <sd-feather-icons type="alert-circle"/>
          </div>
          <div class="ant-popover-message-title">
            <p>{{title}}</p>
            <p v-if="displayedMessage">{{displayedMessage}}</p>
            <a-textarea v-if="canInput && inputFieldType === 'text'" v-model:value="fieldInput"
              :placeholder="placeholder ? $t(placeholder) : ''" :rows="4"/>
            <a-date-picker v-if="canInput && inputFieldType === 'datePicker'"
              v-model:value="fieldInput"
              :disabledDate="disabledDate"
              :placeholder="placeholder ? $t(placeholder) : ''"
              :inputReadOnly="true" format="DD MMM YYYY"
              dropdownClassName="date-picker-popover"/>
          </div>
        </div>
        <div class="ant-popover-buttons">
          <sd-button @click="handleCancel" :outlined="true" size="small" type="white">
            {{$t(cancelButton)}}
          </sd-button>
          <sd-button @click="handleConfirm" :disabled="disabled" size="small" type="primary">
            {{$t(confirmButton)}}
          </sd-button>
        </div>
      </div>
    </template>
    <slot></slot>
  </a-popover>
</template>

<script>
import {
  watch, computed, defineComponent, ref
} from 'vue';
import _ from 'lodash';
import VueTypes from 'vue-types';
import moment from 'moment';

export default defineComponent({
  name: 'PopOver',
  emits: ['confirm', 'cancel'],
  props: {
    title: VueTypes.string,
    reason: VueTypes.string.def(''),
    displayedMessage: VueTypes.string,
    placeholder: VueTypes.string.def(''),
    inputFieldType: VueTypes.string.def('text'),
    requiredInput: VueTypes.bool.def(false),
    canInput: VueTypes.bool.def(true),
    placement: VueTypes.string.def('topLeft'),
    action: VueTypes.string.def('click'),
    confirmButton: VueTypes.string.def('actions.confirm'),
    cancelButton: VueTypes.string.def('actions.cancel')
  },
  setup(props, context) {
    const fieldInput = ref('');
    const visible = ref(false);
    const disabled = computed(() => props.requiredInput ? _.isEmpty(fieldInput.value) : false);
    const handleConfirm = () => {
      context.emit('confirm', fieldInput.value);
      visible.value = false;
    };
    const handleCancel = () => {
      context.emit('cancel', fieldInput.value);
      visible.value = false;
    };
    watch(visible, (value) => {
      if (value === false) {
        fieldInput.value = '';
      } else {
        fieldInput.value = props.reason;
      }
    });
    const disabledDate = (current) => {
      return current && current < moment().endOf('day');
    };

    return {
      fieldInput,
      visible,
      disabled,
      disabledDate,
      handleConfirm,
      handleCancel
    };
  }
});
</script>
<style lang="scss">
.date-picker-popover{
  z-index: 100000!important;
}
.ant-popover {
  position: absolute!important;
}
</style>
